import React from "react";

export const LeafVectorGold = ({ className = "", style = {} }) => (
  <svg
    className={className}
    style={style}
    width="285"
    height="204"
    viewBox="0 0 285 204"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 203L0.764448 202.916L1.21466 203.128L1 203ZM130.818 148.776L130.793 148.527L130.818 148.776ZM284 1.00014L284.239 1.07234L284.493 0.231284L283.835 0.812738L284 1.00014ZM156.049 52.3L156.012 52.0528L156.049 52.3ZM1.21466 203.128C11.7146 185.539 47.2772 157.284 130.842 149.024L130.793 148.527C47.1545 156.793 11.4019 185.087 0.785339 202.872L1.21466 203.128ZM130.842 149.024C247.583 137.486 275.01 31.6671 284.239 1.07234L283.761 0.927941C274.518 31.5686 247.165 137.025 130.793 148.527L130.842 149.024ZM283.835 0.812738C270.184 12.8657 235.427 40.1517 156.012 52.0528L156.086 52.5472C235.606 40.6304 270.45 13.2978 284.165 1.18754L283.835 0.812738ZM156.012 52.0528C90.6913 61.8417 57.9794 82.3183 38.546 109.01C28.8346 122.349 22.4495 137.226 16.9624 153.064C14.219 160.983 11.6988 169.146 9.10037 177.477C6.50154 185.809 3.82411 194.311 0.764448 202.916L1.23555 203.084C4.29887 194.469 6.97889 185.958 9.5777 177.626C12.177 169.292 14.6946 161.137 17.4349 153.228C22.9153 137.409 29.2812 122.585 38.9502 109.305C58.2779 82.7578 90.8444 62.3243 156.086 52.5472L156.012 52.0528Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="65.7975"
        y1="143.752"
        x2="283.64"
        y2="51.8375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDD9D9" />
        <stop offset="1" stopColor="#F7C514" />
      </linearGradient>
    </defs>
  </svg>
);
