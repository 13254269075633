import { State } from '@hookstate/core';
import React from 'react';
import Slider from 'react-slick';

interface ITopImagesSlider {
  setHover: State<string>;
  modalOpen: State<boolean>;
  product: State<number>;
}

const TopImagesSlider = (props: ITopImagesSlider) => {
  const { setHover, modalOpen, product } = props;

  const settings = {
    dots: false,
    draggable: true,
    arrow: false,
    fade: false,
    infinite: false,
    variableWidth: false,
    centermode: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {}
      }
    ]
  };
  return (
    <>
      <div className="w-full h-full relative flex flex-col">
        <Slider {...settings} className="top-mobile-slider w-full">
          <div className="h-full">
            <a
              style={
                setHover.get() === 'wash'
                  ? { filter: 'drop-shadow(-10px -2px 60px rgba(245, 197, 24, 0.4))' }
                  : {}
              }
              onClick={() => {
                modalOpen.set(true);
                product.set(2);
              }}
              className="w-full h-full flex items-center justify-center"
            >
              <img
                src="/images/face-wash.png"
                className="w-4/5 h-4/5 object-contain"
                alt="facewash"
              />
            </a>
          </div>
          <div className="h-full">
            <a
              style={
                setHover.get() === 'wash'
                  ? { filter: 'drop-shadow(-10px -2px 60px rgba(245, 197, 24, 0.4))' }
                  : {}
              }
              onClick={() => {
                modalOpen.set(true);
                product.set(0);
              }}
              className="w-full h-full flex items-center justify-center"
            >
              <img src="/images/serum.png" className="w-4/5 h-4/5 object-contain" alt="serum" />
            </a>
          </div>
          <div className="h-full">
            <a
              style={
                setHover.get() === 'wash'
                  ? { filter: 'drop-shadow(-10px -2px 60px rgba(245, 197, 24, 0.4))' }
                  : {}
              }
              onClick={() => {
                modalOpen.set(true);
                product.set(1);
              }}
              className="w-full h-full flex items-center justify-center"
            >
              <img src="/images/cream.png" className="w-4/5 h-4/5 object-contain" alt="cream" />
            </a>
          </div>
        </Slider>
      </div>
    </>
  );
};

export default TopImagesSlider;
