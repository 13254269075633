import { State } from '@hookstate/core';
import React from 'react';
import { CodIcon } from '../../../static/svg/CodIcon';
import { FreeShipIcon } from '../../../static/svg/FreeShipIcon';
import { SatisfactionIcon } from '../../../static/svg/SatisfactionIcon';
import { currencySymbols } from '../../components/constants';
import { attributesType, tabsType } from '../../components/libs/typeInterface';
import ProductFeatures from './ProductFeatures';
import ProductOtherDetails from './ProductOtherDetails';

interface IProductData {
  isModal?: boolean;
  index: number;
  name: string;
  readLink: string;
  link: string;
  tag: string;
  size: string;
  price: number;
  mrp: number;
  attributes: attributesType[];
  tabs: tabsType[];
  openRedirectModal: State<boolean>;
  productType?: string;
}

const ProductData = (props: IProductData) => {
  const {
    isModal,
    index,
    name,
    readLink,
    link,
    tag,
    size,
    price,
    mrp,
    attributes,
    tabs,
    openRedirectModal,
    productType
  } = props;

  function openStore(proLink: string) {
    openRedirectModal.set(true);
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setTimeout(function () {
      document.getElementsByTagName('body')[0].removeAttribute('style');
      openRedirectModal.set(false);
      window.location.href = proLink;
    }, 5000);
  }

  console.log('index', index);

  return (
    <>
      <div
        className={
          `xs:w-full lg:w-1/2 flex items-center justify-center xs:py-5` +
          `${isModal ? '' : ' xs:px-4 lg:px-0'}` +
          `${!isModal && index === 0 ? ' bg-customBackground-400' : ''}` +
          `${!isModal && index === 1 ? ' xs:order-1 lg:order-0 bg-customBackground-500' : ''}` +
          `${
            (!isModal && index === 2) || (!isModal && index === 3) ? ' bg-customBackground-300' : ''
          }` +
          `${!isModal && index === 3 ? ' lg:pb-10' : ' lg:py-0'}`
        }
      >
        <div className="xs:w-full lg:w-9/12 flex items-start justify-start flex-wrap">
          {!isModal && (
            <p className="uppercase text-sm text-customColors-500 w-full text-left">{tag}</p>
          )}
          <a
            href={readLink}
            className="font-breadley-sans text-customColors-200 xs:text-3xl lg:text-5xl uppercase w-full text-left"
          >
            {name}
          </a>
          {productType === 'kit' ? (
            <p className="text-1sm mt-3">{size}</p>
          ) : (
            <p className="text-1sm mt-3">({size})</p>
          )}
          <div className="w-full flex items-start justify-start flex-wrap mt-4">
            <ProductFeatures tabs={tabs} link={readLink} />
          </div>
          <div
            className={`w-full mb-4 flex flex-wrap items-start justify-between${
              isModal ? '' : ' space-y-3'
            }`}
          >
            <ProductOtherDetails attributes={attributes} />
          </div>
          <div className="flex flex-col items-start justify-center">
            <p>
              MRP :{' '}
              <del className="text-red-500 font-normal">
                {currencySymbols.rupee}
                {mrp}
              </del>
              <span className="text-xl ml-1">
                {currencySymbols.rupee}
                {price}
              </span>
            </p>
            <p className="text-customColors-300 text-sm">Incl. of all taxes</p>
          </div>
          <div className="w-full flex mt-6 relative">
            <a
              target="_blank"
              onClick={() => {
                openStore(link);
              }}
              className={
                `cursor-pointer xs:w-full lg:w-9/12 py-2 text-customColors-300 xs:text-sm lg:text-xl text-center uppercase rounded-sm transition-all duration-300 shop-now` +
                `${!isModal && index === 0 ? ' shade-1' : ''}` +
                `${!isModal && index === 1 ? ' shade-2' : ''}` +
                `${(!isModal && index === 2) || (!isModal && index === 3) ? ' shade-3' : ''}` +
                `${isModal ? ' shade-0' : ' z-10'}`
              }
            >
              {/* Shop Now */}
              Know More
            </a>
          </div>

          {isModal && (
            <div className="xs:w-full lg:w-9/12">
              <div className="w-full flex flex-row justify-between items-start py-4">
                <div className="flex items-center justify-center flex-wrap">
                  <FreeShipIcon className="w-5 h-5" />
                  <p className=" w-full text-center text-xs capitalize mt-2 px-4">Free Shipping</p>
                </div>

                <div className="flex items-center justify-center flex-wrap">
                  <CodIcon className="w-5 h-5" />
                  <p className=" w-full text-center text-xs capitalize mt-2">Cash On Delivery</p>
                </div>

                <div className="flex items-center justify-center flex-wrap">
                  <SatisfactionIcon className="w-5 h-5" />
                  <p className=" w-full text-center text-xs capitalize mt-2">
                    Satisfaction Guaranteed
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductData;
