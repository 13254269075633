import React from 'react';
import Slider from 'react-slick';
import { mediaType } from '../../components/libs/typeInterface';

interface IProductSlider {
  media: mediaType[];
  name: string;
}

const ProductSlider = (props: IProductSlider) => {
  const { media, name } = props;
  const settings = {
    dots: true,
    draggable: true,
    arrow: false,
    fade: true,
    infinite: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {}
      }
    ]
  };
  return (
    <div className="w-full lg:h-full relative flex flex-col">
      <Slider
        {...settings}
        className="product-slider mb-6 w-full lg:h-full bg-leaf bg-contain bg-no-repeat bg-bottom"
      >
        {media.map((item, index) => (
          <div key={index} className="h-full">
            <div className="w-full h-full flex items-center justify-center">
              <img
                src={item.value}
                className="w-4/5 h-4/5 object-contain"
                alt={name + ` 0` + item.id}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductSlider;
