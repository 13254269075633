import React from "react";

export const CuratedIcon = ({ className = "", style = {} }) => (
  <svg
    className={className}
    style={style}
    width="66"
    height="58"
    viewBox="0 0 66 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.7222 31.0238H3C1.89543 31.0238 1 31.9192 1 33.0238V41.6904C1 49.9747 7.71572 56.6904 16 56.6904H49.3889C57.6732 56.6904 64.3889 49.9747 64.3889 41.6904V33.0238C64.3889 31.9192 63.4935 31.0238 62.3889 31.0238H48.0556M24.7222 31.0238L43.9442 14.7591C44.5516 14.245 44.7966 13.4198 44.5679 12.6576L42.9251 7.1815C42.7294 6.52925 42.8791 5.82226 43.3222 5.30523L45.9669 2.21979C46.6894 1.37691 47.9601 1.28372 48.7978 2.01216L63.4186 14.7259C64.3397 15.5269 64.3346 16.9592 63.4078 17.7536L60.3816 20.3475C59.961 20.708 59.4097 20.8779 58.8591 20.8168L54.1969 20.2987C53.3211 20.2014 52.4849 20.6887 52.1377 21.4987L48.0556 31.0238M24.7222 31.0238H48.0556"
      stroke="url(#paint0_linear)"
      strokeWidth="1.5"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="12.1047"
        y1="49.9905"
        x2="51.5822"
        y2="18.1932"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
