import React from 'react';
import styled from 'styled-components';
import { LeafVector } from '../../static/svg/LeafVector';

const DividerSection = () => {
  const DiscoverBox = styled.div`
    @media (min-width: 1025px) {
      & > img {
        opacity: 1;
      }
      & > div {
        opacity: 0;
      }
      & > div > p:nth-child(3) {
        opacity: 0;
      }
      & :hover > div {
        opacity: 1;
      }
      & :hover > div > p:nth-child(3) {
        opacity: 1;
      }
      & :hover > img {
        opacity: 0;
      }
    }
  `;

  return (
    <>
      <section id="discover" className="w-full flex flex-wrap">
        <div className="xs:px-4 lg:px-0 relative w-full flex flex-col items-center justify-center lg:container lg:mx-auto py-8">
          <LeafVector className="absolute h-full z-1 lg:right-80" />
          <p className="w-full text-center font-breadley-sans text-customColors-300 xs:text-4xl lg:text-6xl xs:mb-4 lg:mb-6">
            A Balance of Nature & Chemistry
          </p>
          <p className="w-full lg:w-1/2 text-center text-primary text-base lg:px-10">
            Get Gorgeous Skin Naturally with Skincare That Works
          </p>
        </div>
        <div className="w-full flex items-center justify-center xs:flex-wrap lg:flex-nowrap">
          <DiscoverBox className="w-full lg:w-1/3 relative flex items-center justify-center flex-wrap">
            <img
              src="/images/serum.jpg"
              className="w-full h-full object-contain transition-all duration-400"
            />
            <div className="xs:relative lg:absolute xs:px-4 lg:px-0 w-full lg:h-full xs:py-5 lg:py-0 flex content-center items-center justify-center flex-wrap lg:text-white bg-customBackground-100 z-9 transition-all duration-400">
              <p className="text-center xs:text-3xl lg:text-5xl font-breadley-sans border-b border-white pb-2">
                R&G Face Serum
              </p>
              <p className="w-full text-center xs:text-2xl lg:text-3xl font-breadley-sans xs:my-2 lg:my-4 pb-2">
                Skin Brightening
              </p>
              <p className="w-full text-center text-base xs:mb-6 lg:mb-10 px-5 lg:transition-all lg:duration-200">
                Expertly formulated with the most stable derivative of Vitamin-C, this
                fast-absorbing & Non-Sticky face serum is fortified with Niacinamide, Ferulic acid
                and Natural Moisturizing Factors, which work synergistically to help boost collagen
                synthesis, thereby reducing fine lines wrinkles. It fades dark spots, evens skin
                tone and helps promote visibly radiant, smoother and healthier-looking skin
              </p>
              <a
                href="/serum"
                className="text-center text-lg default-a after:bg-white reverse relative overflow-hidden"
              >
                Know more
              </a>
            </div>
          </DiscoverBox>
          <DiscoverBox className="w-full lg:w-1/3 relative flex items-center justify-center flex-wrap">
            <img
              src="/images/facewash.jpg"
              className="w-full h-full object-contain transition-all duration-400"
            />
            <div className="xs:relative lg:absolute xs:px-4 lg:px-0 w-full lg:h-full xs:py-5 lg:py-0 flex content-center items-center justify-center flex-wrap text-customColors-300 bg-customColors-600 z-9 transition-all duration-400">
              <p className="text-center xs:text-3xl lg:text-5xl font-breadley-sans border-b border-customColors-300 pb-2">
                R&G Face Wash
              </p>
              <p className="w-full text-center xs:text-2xl lg:text-3xl font-breadley-sans xs:my-2 lg:my-4 pb-2">
                Cleanses – Exfoliates - Brightens
              </p>
              <p className="w-full text-center text-base xs:mb-6 lg:mb-10 px-5 lg:transition-all lg:duration-200">
                Regular exposure to pollution and stress makes our skin look dull and darker. R&G
                Face wash is fortified with potent ingredients like Licorice, Cucumber, Niacinamide,
                ProVitamin B5 and Glycolic acid, to cleanse and remove dead skin cells, help reduce
                pigmentation and dullness to uncover a healthy, younger and brighter skin.
              </p>
              <a
                href="/facewash"
                className="text-center text-lg default-a after:bg-customColors-300 reverse relative overflow-hidden"
              >
                Know more
              </a>
            </div>
          </DiscoverBox>
          <DiscoverBox className="w-full lg:w-1/3 relative flex items-center justify-center flex-wrap">
            <img
              src="/images/cream.jpg"
              className="w-full h-full object-contain transition-all duration-400"
            />
            <div className="xs:relative lg:absolute xs:px-4 lg:px-0 w-full lg:h-full xs:py-5 lg:py-0 flex content-center items-center justify-center flex-wrap text-white bg-customBackground-100 z-9 transition-all duration-400">
              <p className="text-center xs:text-3xl lg:text-5xl font-breadley-sans border-b border-white pb-2">
                R&G Face Cream
              </p>
              <p className="w-full text-center xs:text-2xl lg:text-3xl font-breadley-sans xs:my-2 lg:my-4 pb-2">
                Skin Brightening with SPF-15
              </p>
              <p className="w-full text-center text-base xs:mb-6 lg:mb-10 px-5 lg:transition-all lg:duration-200">
                A rich combination of clinically-proven Plant actives, Vitamins, Alpha-Hydroxy acid
                (AHA) & UV filters, which helps reduce hyper-pigmentation & dark spots and evens out
                skin tone while protecting from harmful UV radiation, for a visibly brighter and
                youthful appearance.
              </p>
              <a
                href="/cream"
                className="text-center text-lg default-a after:bg-white reverse relative overflow-hidden"
              >
                Know more
              </a>
            </div>
          </DiscoverBox>
        </div>
      </section>
    </>
  );
};
export default DividerSection;
