import { useState } from '@hookstate/core';
import React from 'react';
import { productList } from '../../components/constants';
import { productKitArray } from '../../components/libs/constants';
import ClickModal from '../../components/Shared/ClickModal';
import ProductData from './ProductData';
import ProductImage from './ProductImage';

interface IProductDetails {
  isModal?: boolean;
  modalProduct?: number;
}

const ProductDetails = (props: IProductDetails) => {
  const { isModal, modalProduct } = props;
  const openRedirectModal = useState(false);

  return (
    <>
      <section id={!isModal ? `essentials` : ''} className="w-full flex flex-wrap">
        {!isModal && (
          <div className="xs:px-4 lg:px-0 relative w-full flex flex-col items-center justify-center lg:container lg:mx-auto py-8">
            <div className="w-full flex items-center flex-col">
              <div className="uppercase text-base relative flex flex-col items-center overflow-hidden">
                <p className="mb-3">BRIGHTENING RANGE</p>
                <p className="flex w-11/12 bg-golden mb-1" style={{ height: '1px' }} />
              </div>
              <p className="uppercase font-breadley-sans text-customColors-300 xs:text-4xl lg:text-6xl">
                SHOP SKIN ESSENTIALS
              </p>
            </div>
          </div>
        )}
        {!isModal &&
          productList &&
          productList.map((product, index) => {
            return index === 1 ? (
              <div key={index} className="w-full flex flex-row xs:flex-wrap lg:flex-nowrap">
                <ProductData
                  index={index}
                  isModal={isModal}
                  name={product.name}
                  readLink={product.readLink}
                  link={product.link}
                  tag={product.tag}
                  size={product.size}
                  price={product.price}
                  mrp={product.mrp}
                  attributes={product.attributes}
                  tabs={product.tabs}
                  openRedirectModal={openRedirectModal}
                />
                <ProductImage
                  index={index}
                  isModal={isModal}
                  name={product.name}
                  media={product.media}
                  img={product.img}
                  link={product.readLink}
                />
              </div>
            ) : (
              <div key={index} className="w-full flex flex-row xs:flex-wrap lg:flex-nowrap">
                <ProductImage
                  index={index}
                  isModal={isModal}
                  name={product.name}
                  media={product.media}
                  img={product.img}
                  link={product.readLink}
                />
                <ProductData
                  index={index}
                  isModal={isModal}
                  name={product.name}
                  readLink={product.readLink}
                  link={product.link}
                  tag={product.tag}
                  size={product.size}
                  price={product.price}
                  mrp={product.mrp}
                  attributes={product.attributes}
                  tabs={product.tabs}
                  openRedirectModal={openRedirectModal}
                />
              </div>
            );
          })}

        {!isModal &&
          productKitArray &&
          productKitArray.map((product, index) => {
            return index % 2 === 1 ? (
              <div key={index} className="w-full flex flex-row xs:flex-wrap lg:flex-nowrap">
                <ProductImage
                  index={index}
                  isModal={isModal}
                  name={product.name}
                  media={product.media}
                  img={product.img}
                  link={product.id}
                />
                <ProductData
                  index={index}
                  isModal={isModal}
                  name={product.name}
                  readLink={product.id}
                  link={product.link}
                  tag={product.tag}
                  size={product.size}
                  price={product.price}
                  mrp={product.mrp}
                  attributes={product.attributes}
                  tabs={product.tabs}
                  openRedirectModal={openRedirectModal}
                  productType="kit"
                />
              </div>
            ) : (
              <div key={index} className="w-full flex flex-row xs:flex-wrap lg:flex-nowrap">
                <ProductData
                  index={index}
                  isModal={isModal}
                  name={product.name}
                  readLink={product.id}
                  link={product.link}
                  tag={product.tag}
                  size={product.size}
                  price={product.price}
                  mrp={product.mrp}
                  attributes={product.attributes}
                  tabs={product.tabs}
                  openRedirectModal={openRedirectModal}
                  productType="kit"
                />
                <ProductImage
                  index={index}
                  isModal={isModal}
                  name={product.name}
                  media={product.media}
                  img={product.img}
                  link={product.id}
                />
              </div>
            );
          })}

        {isModal &&
          productList &&
          productList.map((product, index) => {
            if (index === modalProduct) {
              return (
                <div key={index} className="w-full flex xs:flex-col lg:flex-row lg:flex-nowrap">
                  <ProductImage
                    index={index}
                    isModal={isModal}
                    name={product.name}
                    media={product.media}
                    link={product.readLink}
                  />
                  <ProductData
                    index={index}
                    isModal={isModal}
                    name={product.name}
                    readLink={product.readLink}
                    link={product.link}
                    tag={product.tag}
                    size={product.size}
                    price={product.price}
                    mrp={product.mrp}
                    attributes={product.attributes}
                    tabs={product.tabs}
                    openRedirectModal={openRedirectModal}
                  />
                </div>
              );
            }
          })}
      </section>
      {openRedirectModal.get() && (
        <ClickModal openRedirectModal={openRedirectModal} isOpen={openRedirectModal.get()} />
      )}
    </>
  );
};

export default ProductDetails;
