import React from 'react';
import { LeafVectorGold } from '../../static/svg/LeafVectorGold';

const LuxurySection = () => {
  return (
    <>
      <section id="luxury" className="w-full flex flex-wrap">
        <div className="xs:px-4 lg:px-0 relative w-full flex flex-col items-center justify-center lg:container lg:mx-auto py-8">
          <p className="xs:w-full lg:w-3/4 text-center font-breadley-sans text-customColors-300 xs:text-4xl lg:text-6xl xs:mb-4 lg:mb-5">
            Transforming Skincare with Nature’s Choicest Ingredients
          </p>
          <p className="w-full lg:w-3/4 text-center text-primary text-base xs:mb-5 lg:px-10 lg:mt-5 lg:mb-10">
            Rose & Gold was founded with the passion for environmentalism, a commitment to wellness,
            and a dedication for only the best skincare routine. As a premium brand of Vasu
            Healthcare, Rose & Gold skincare products are recognised around the globe for their
            holistic, all natural properties that can help various skin conditions and radiate
            healthy, glowing skin.
          </p>
          <a
            target="_blank"
            href="https://www.vasustore.com/skin-care/r-g-skin-brightening-range"
            className="text-center text-base lg:text-lg default-a after:bg-primary reverse relative overflow-hidden"
          >
            {/* Shop Now */}
            Know More
          </a>
        </div>
        <div className="relative w-full h-full flex flex-wrap xs:py-8 lg:py-16">
          <div className="w-full h-full absolute left-0 top-0 flex items-center justify-center">
            <img
              src="/images/luxury-on-your-fingertip-bg-img.jpg"
              className="w-full h-full object-cover z-1"
            />
          </div>
          <div className="w-full h-full flex items-center justify-start">
            <div className="xs:px-4 lg:px-0 w-full flex lg:container lg:mx-auto">
              <div className="xs:w-full lg:w-1/2 relative flex items-center justify-end flex-wrap bg-customBackground-100 text-white xs:px-5 xs:py-10 lg:px-10 lg:py-14 bg-opacity-95">
                <LeafVectorGold className="absolute top-0 right-0" />
                <div className="w-full lg:pt-20 lg:pb-12">
                  <p className="uppercase text-white">Why</p>
                  <p className="w-full text-left font-breadley-sans xs:text-4xl lg:text-6xl mb-8">
                    Rose & Gold
                  </p>
                  <p className="text-base leading-relaxed lg:pr-14">
                    We bring you products that are safe and highly effective. All of our formulas
                    are original, developed and tested by us at Vasu Research Centre. Knowing the
                    importance of a functional beauty regime and the challenges around skincare, we
                    ethically source the finest raw natural materials the earth has to offer. Most
                    of the ingredients we use have been around for thousands of years and have
                    proven remedial properties that enhance natural beauty.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LuxurySection;
