import React from 'react';

export const NoChemicalIcon = ({ className = '' }) => (
  <svg
    className={className}
    width="30"
    height="29"
    viewBox="0 0 30 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.42815 1.27274H11.2463M21.0645 1.27274H19.2463M11.2463 1.27274H19.2463M11.2463 1.27274V8.9091M19.2463 1.27274V8.9091M19.2463 8.9091V11.2004C19.2463 11.6029 19.3678 11.996 19.5947 12.3283L26.2239 22.0354C27.5838 24.0266 26.1578 26.7273 23.7465 26.7273H6.74613C4.33484 26.7273 2.90885 24.0266 4.26873 22.0354L10.8979 12.3283C11.1249 11.996 11.2463 11.6029 11.2463 11.2004V8.9091M19.2463 8.9091H11.2463M1.09677 27.871L26.9032 4.12903"
      stroke="url(#paint0_linear)"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <ellipse cx="18.0968" cy="20.1935" rx="1.54839" ry="1.54839" fill="url(#paint1_linear)" />
    <circle cx="11.3871" cy="17.6129" r="1.03226" fill="url(#paint2_linear)" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="7.55884"
        y1="20.0695"
        x2="30.8742"
        y2="12.5817"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="17.2574"
        y1="20.8336"
        x2="19.8321"
        y2="20.0582"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="10.8275"
        y1="18.0396"
        x2="12.544"
        y2="17.5227"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
