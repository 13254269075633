import React from "react";

export const LegacyIcon = ({ className = "" }) => (
  <svg
    className={className}
    width="20"
    height="34"
    viewBox="0 0 20 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.5C9.20438 0.5 8.44132 0.816071 7.87871 1.37868C7.3161 1.94129 7.00003 2.70435 7.00003 3.5C7.00003 4.29565 7.3161 5.05871 7.87871 5.62132C8.44132 6.18393 9.20438 6.5 10 6.5C10.7957 6.5 11.5587 6.18393 12.1213 5.62132C12.684 5.05871 13 4.29565 13 3.5C13 2.70435 12.684 1.94129 12.1213 1.37868C11.5587 0.816071 10.7957 0.5 10 0.5ZM8.87503 8C7.0244 8 5.50003 9.52437 5.50003 11.375V17.375C5.49771 17.5562 5.53919 17.7353 5.62094 17.897C5.70269 18.0587 5.82229 18.1983 5.96957 18.3039C6.11685 18.4094 6.28745 18.4779 6.46686 18.5033C6.64627 18.5288 6.82918 18.5106 7.00003 18.4502V23.75H2.87503C2.62785 23.7473 2.38666 23.8261 2.18872 23.9741C1.99079 24.1222 1.84713 24.3314 1.77995 24.5693C1.71277 24.8072 1.7258 25.0605 1.81703 25.2903C1.90826 25.52 2.07262 25.7133 2.2847 25.8403L1.20071 31.2632C0.92063 31.3068 0.667336 31.4546 0.491478 31.6769C0.31562 31.8992 0.230159 32.1797 0.252182 32.4623C0.274206 32.7449 0.402091 33.0088 0.610267 33.2012C0.818444 33.3936 1.09157 33.5003 1.37503 33.5H18.625C18.9102 33.5028 19.1859 33.3973 19.3962 33.2046C19.6065 33.012 19.7358 32.7466 19.7579 32.4623C19.7801 32.178 19.6934 31.8958 19.5155 31.6729C19.3375 31.45 19.0815 31.3031 18.7993 31.2617L17.7154 25.8403C17.9274 25.7133 18.0918 25.52 18.183 25.2903C18.2743 25.0605 18.2873 24.8072 18.2201 24.5693C18.1529 24.3314 18.0093 24.1222 17.8113 23.9741C17.6134 23.8261 17.3722 23.7473 17.125 23.75H13V18.4502C13.1709 18.5106 13.3538 18.5288 13.5332 18.5033C13.7126 18.4779 13.8832 18.4094 14.0305 18.3039C14.1778 18.1983 14.2974 18.0587 14.3791 17.897C14.4609 17.7353 14.5023 17.5562 14.5 17.375V11.375C14.5 9.52437 12.9757 8 11.125 8H8.87503ZM9.25003 19.25H10.75V23.75H9.25003V19.25ZM4.54788 26H7.94046C8.06123 26.0198 8.18443 26.0198 8.3052 26H11.6905C11.8112 26.0198 11.9344 26.0198 12.0552 26H15.4522L16.5025 31.25H3.49759L4.54788 26Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="4.7165"
        y1="23.8209"
        x2="21.8666"
        y2="20.7669"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
