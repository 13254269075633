import React from 'react';
import { CrultyFree } from '../../static/svg/CrultyFree';
import { CuratedIcon } from '../../static/svg/CuratedIcon';
import { NoChemicalIcon } from '../../static/svg/NoChemicalIcon';

const FreeShipSection = () => {
  return (
    <>
      <section id="discover" className="w-full flex flex-wrap">
        <div className="xs:px-4 lg:px-0 relative w-full flex flex-col items-center justify-center lg:container lg:mx-auto py-8">
          <div className="w-full flex flex-nowrap justify-between items-center py-4 lg:-mx-5">
            <div className="xs:flex-1 lg:flex-none lg:w-auto flex items-center flex-wrap justify-center lg:pr-5">
              <NoChemicalIcon className="xs:w-10 xs:h-10 lg:w-12 lg:h-12" />
              <p className="w-full text-center text-customColors-300 xs:text-base lg:text-lg capitalize mt-2 lg:mt-4">
                No Harmful Chemicals
              </p>
            </div>

            <div className="xs:flex-1 lg:flex-none lg:w-auto flex items-center flex-wrap justify-center px-5">
              <CrultyFree className="xs:w-10 xs:h-10 lg:w-12 lg:h-12" />
              <p className="w-full text-center text-customColors-300 xs:text-base lg:text-lg capitalize mt-2 lg:mt-4">
                Cruelty Free
              </p>
            </div>

            <div className="xs:flex-1 lg:flex-none lg:w-auto flex items-center flex-wrap justify-center lg:pl-5">
              <CuratedIcon className="xs:w-10 xs:h-10 lg:w-12 lg:h-12" />
              <p className="w-full text-center text-customColors-300 xs:text-base lg:text-lg capitalize mt-2 lg:mt-4">
                Curated In India
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FreeShipSection;
