import React from "react";

export const ProductsIcon = ({ className = "" }) => (
  <svg
    className={className}
    width="26"
    height="30"
    viewBox="0 0 26 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.625 0.757813C8.79566 0.757795 7.96611 1.07051 7.33984 1.69678L3.03613 6.00049H1.375C1.07664 6.00052 0.79051 6.11905 0.579538 6.33003C0.368566 6.541 0.25003 6.82713 0.25 7.12549V25.8755C0.25 27.7261 1.77437 29.2505 3.625 29.2505H22.375C24.2256 29.2505 25.75 27.7261 25.75 25.8755V7.12549C25.75 6.82713 25.6314 6.541 25.4205 6.33003C25.2095 6.11905 24.9234 6.00052 24.625 6.00049H22.8408L19.2241 2.38232C18.6494 1.80757 17.8869 1.521 17.125 1.521C16.3631 1.521 15.6021 1.80757 15.0273 2.38232L13.8115 3.59814L11.9102 1.69824L11.9087 1.69678C11.2829 1.07121 10.4537 0.75783 9.625 0.757813ZM9.625 2.99316C9.8736 2.99315 10.1219 3.0914 10.3179 3.2876L13.0293 6.00049H6.21924L8.93067 3.2876C9.12715 3.09111 9.3764 2.99318 9.625 2.99316ZM17.125 3.75488C17.3062 3.75488 17.4881 3.8279 17.6333 3.97314L19.6592 6.00049H16.2139L15.4023 5.18896L16.6182 3.97314C16.7634 3.8279 16.9438 3.75488 17.125 3.75488ZM2.5 8.25049H22.208C22.3302 8.27081 22.4549 8.27081 22.5772 8.25049H23.5V25.8755C23.5 26.5104 23.0099 27.0005 22.375 27.0005H3.625C2.99013 27.0005 2.5 26.5104 2.5 25.8755V8.25049ZM9.625 12.7505C9.47592 12.7484 9.32792 12.7759 9.18958 12.8315C9.05124 12.8871 8.92533 12.9696 8.81916 13.0743C8.71299 13.179 8.62869 13.3037 8.57115 13.4413C8.51361 13.5788 8.48398 13.7264 8.48398 13.8755C8.48398 14.0246 8.51361 14.1722 8.57115 14.3097C8.62869 14.4473 8.71299 14.572 8.81916 14.6767C8.92533 14.7813 9.05124 14.8639 9.18958 14.9195C9.32792 14.9751 9.47592 15.0026 9.625 15.0005H16.375C16.5241 15.0026 16.6721 14.9751 16.8104 14.9195C16.9488 14.8639 17.0747 14.7813 17.1808 14.6767C17.287 14.572 17.3713 14.4473 17.4289 14.3097C17.4864 14.1722 17.516 14.0246 17.516 13.8755C17.516 13.7264 17.4864 13.5788 17.4289 13.4413C17.3713 13.3037 17.287 13.179 17.1808 13.0743C17.0747 12.9696 16.9488 12.8871 16.8104 12.8315C16.6721 12.7759 16.5241 12.7484 16.375 12.7505H9.625Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="6.08865"
        y1="20.8934"
        x2="27.6459"
        y2="15.083"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
