import { State, useState } from '@hookstate/core';
import React from 'react';
import ReactModal from 'react-modal';
import ProductDetails from '../ProductDetails/ProductDetails';
import TopImages from './TopImages';
import TopImagesSlider from './TopImagesSlider';

const MainImage = (props: { openRedirectModal: State<boolean> }) => {
  const { openRedirectModal } = props;
  const modalOpen = useState(false);
  const product = useState(-1);
  const setHover = useState('');

  function openStore(proLink: string) {
    openRedirectModal.set(true);
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setTimeout(function () {
      document.getElementsByTagName('body')[0].removeAttribute('style');
      openRedirectModal.set(false);
      window.location.href = proLink;
    }, 5000);
  }

  return (
    <>
      <section id="home" className="w-full flex">
        <div className="relative w-full h-full flex items-center justify-center overflow-hidden">
          <div className="left-0 top-0 w-full h-full absolute bg-customBackground-200 z-1">
            <img
              src="/images/main-banner-img.jpg"
              className="w-full h-full object-cover"
              alt="main banner image"
            />
          </div>
          <div className="relative w-full lg:h-full flex z-10 lg:py-6">
            <div className="xs:p-4 lg:p-0 flex w-full xs:flex-wrap h-full lg:flex-nowrap lg:container lg:mx-auto">
              <div className="xs:w-full lg:w-2/5 flex flex-col items-start justify-center lg:pr-14">
                <p className="w-full font-breadley-sans text-7xl text-left">Beauty with Purity</p>
                <div className="w-full flex flex-row items-center justify-between mt-5">
                  <a
                    onMouseOver={() => setHover.set('serum')}
                    onMouseOut={() => setHover.set('')}
                    onClick={() => {
                      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
                      modalOpen.set(true);
                      product.set(0);
                    }}
                    className="cursor-pointer relative overflow-hidden default-a after:bg-customColors-100 text-customColors-100 text-base"
                  >
                    Serum
                  </a>
                  <a
                    onMouseOver={() => setHover.set('cream')}
                    onMouseOut={() => setHover.set('')}
                    onClick={() => {
                      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
                      modalOpen.set(true);
                      product.set(1);
                    }}
                    className="cursor-pointer relative overflow-hidden default-a after:bg-customColors-100 text-customColors-100 text-base"
                  >
                    Cream
                  </a>
                  <a
                    onMouseOver={() => setHover.set('wash')}
                    onMouseOut={() => setHover.set('')}
                    onClick={() => {
                      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
                      modalOpen.set(true);
                      product.set(2);
                    }}
                    className="cursor-pointer relative overflow-hidden default-a after:bg-customColors-100 text-customColors-100 text-base"
                  >
                    Facewash
                  </a>
                </div>
                <div className="w-full flex flex-row items-center justify-start mt-14">
                  <a
                    onClick={() =>
                      openStore('https://www.vasustore.com/skin-care/r-g-skin-brightening-range')
                    }
                    target="_blank"
                    // href="https://www.vasustore.com/skin-care/r-g-skin-brightening-range"
                    className="cursor-pointer text-base relative overflow-hidden default-a after:bg-primary reverse"
                  >
                    {/* Shop Now */}
                    Know More
                  </a>
                </div>
              </div>
              <div className="xs:hidden lg:flex-1 lg:flex lg:h-full">
                <TopImages modalOpen={modalOpen} setHover={setHover} product={product} />
              </div>
              <div className="w-full xs:flex mt-5 lg:hidden">
                <TopImagesSlider modalOpen={modalOpen} setHover={setHover} product={product} />
              </div>
            </div>
          </div>
        </div>
        <ReactModal
          isOpen={modalOpen.get()}
          onRequestClose={() => {
            modalOpen.set(false);
            document.getElementsByTagName('body')[0].removeAttribute('style');
          }}
          className="cs-Modal border-none focus:border-none"
          overlayClassName="cs-Overlay"
        >
          <div className="relative w-full lg:max-w-7xl bg-white shadow-lg z-modal overflow-hidden overflow-y-auto border-none focus:border-none xs:max-h-9v lg:max-h-9.5v">
            <div className="lg:hidden w-full absolute top-3 right-3 xs:flex items-center justify-end z-10">
              <a
                onClick={() => modalOpen.set(false)}
                className="cursor-pointer w-6 h-6 rounded-full border-2 border-customColors-300 text-customColors-300 text-lg flex items-center justify-center pb-1"
              >
                x
              </a>
            </div>
            <div className="w-full flex xs:flex-col lg:flex-row xs:p-5 lg:px-10 lg:py-8">
              <ProductDetails isModal modalProduct={product.get()} />
            </div>
          </div>
        </ReactModal>
      </section>
    </>
  );
};

export default MainImage;
