import React from 'react';
import { AwaySunlightIcon } from '../../../static/svg/AwaySunlightIcon';
import { ExternalUseIcon } from '../../../static/svg/ExternalUseIcon';
import { SkinTypeIcon } from '../../../static/svg/SkinTypeIcon';
import { attributesType } from '../../components/libs/typeInterface';

interface IProductOtherDetails {
  attributes: attributesType[];
}

const ProductOtherDetails = (props: IProductOtherDetails) => {
  const { attributes } = props;
  return (
    <>
      {attributes.map((attribute, index) => {
        return (
          <div
            key={index}
            className="w-auto flex flex-nowrap items-center justify-start mt-3 space-x-2"
          >
            {attribute.icon === 'skin' && <SkinTypeIcon className="w-6 h-6" />}
            {attribute.icon === 'external' && <ExternalUseIcon className="w-6 h-6" />}
            {attribute.icon === 'away' && <AwaySunlightIcon className="w-8 h-12" />}
            <p className="text-sm">{attribute.value}</p>
          </div>
        );
      })}
    </>
  );
};

export default ProductOtherDetails;
