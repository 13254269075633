import { useState } from '@hookstate/core';
import { withPrefix } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import BeautySection from '../components/BeautySection/BeautySection';
import CuratedSection from '../components/CuratedSection';
import DividerSection from '../components/DividerSection';
import FreeShipSection from '../components/FreeShipSection';
import MainImage from '../components/HomeHero/MainImage';
import Layout from '../components/Layouts/layout';
import LuxurySection from '../components/LuxurySection';
import ProductDetails from '../components/ProductDetails/ProductDetails';
import SkinRoutineSection from '../components/RoutineSection/SkinRoutineSection';
import ClickModal from '../components/Shared/ClickModal';
import InfluencersSection from '../components/Shared/InfluencersSection/InfluencersSection';
import VasuSection from '../components/VasuSection';

const index = () => {
  const openRedirectModal = useState(false);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rose And Gold Face Wash, Serum, Cream</title>
        <meta name="facebook-domain-verification" content="7zavsnqe2ti2yx2faj448nyhtkrxg5" />
        <meta name="title" content="Rose And Gold Face Wash, Serum, Cream" />
        <meta
          name="description"
          content="Rose and Gold was founded with the passion for environmentalism, a commitment to wellness, and a dedication for only the best skincare routine. It is a balance of nature & chemistry. "
        />
        <meta
          name="keywords"
          content="Face Wash, Face serum, face cream, skincare, facecare, 100% nautral products"
        />
        {/* Global site tag (gtag.js) - Google Analytics  */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-1LB4M0W7V9" />
        <script src={withPrefix('js/script.js')} type="text/javascript" />
      </Helmet>
      <Layout>
        <MainImage openRedirectModal={openRedirectModal} />
        <DividerSection />
        <LuxurySection />
        <CuratedSection />
        <SkinRoutineSection />
        <ProductDetails />
        <FreeShipSection />
        <InfluencersSection />
        <BeautySection />
        <VasuSection />
        {openRedirectModal.get() && (
          <ClickModal openRedirectModal={openRedirectModal} isOpen={openRedirectModal.get()} />
        )}
      </Layout>
    </>
  );
};

export default index;
