import { State } from '@hookstate/core';
import React from 'react';

interface ITopImages {
  setHover: State<string>;
  modalOpen: State<boolean>;
  product: State<number>;
}

const TopImages = (props: ITopImages) => {
  const { setHover, modalOpen, product } = props;
  return (
    <>
      <div className="w-full flex items-center justify-center relative">
        <a
          style={
            setHover.get() === 'wash'
              ? { filter: 'drop-shadow(-10px -2px 60px rgba(245, 197, 24, 0.4))' }
              : {}
          }
          onClick={() => {
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            modalOpen.set(true);
            product.set(2);
          }}
          className="cursor-pointer absolute w-88 top-pro-img fw-img"
        >
          <img src="/images/face-wash.png" className="w-full h-full object-contain" />
        </a>
        <a
          style={
            setHover.get() === 'serum'
              ? { filter: 'drop-shadow(-10px -2px 60px rgba(245, 197, 24, 0.4))' }
              : {}
          }
          onClick={() => {
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            modalOpen.set(true);
            product.set(0);
          }}
          className="cursor-pointer absolute w-80 top-pro-img bottom-0 left-16"
        >
          <img src="/images/serum.png" className="w-full h-full object-contain" />
        </a>
        <a
          style={
            setHover.get() === 'cream'
              ? { filter: 'drop-shadow(-10px -2px 60px rgba(245, 197, 24, 0.4))' }
              : {}
          }
          onClick={() => {
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            modalOpen.set(true);
            product.set(1);
          }}
          className="cursor-pointer absolute w-72 top-pro-img bottom-20 right-0"
        >
          <img src="/images/cream.png" className="w-full h-full object-contain" />
        </a>
      </div>
    </>
  );
};

export default TopImages;
