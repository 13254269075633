import React from 'react';
import { AwardsIcon } from '../../static/svg/AwardsIcon';
import { CaringIcon } from '../../static/svg/CaringIcon';
import { CountriesIcon } from '../../static/svg/CountriesIcon';
import { LegacyIcon } from '../../static/svg/LegacyIcon';
import { ProductsIcon } from '../../static/svg/ProductsIcon';
import { ResearchIcon } from '../../static/svg/ResearchIcon';

const VasuSection = () => {
  return (
    <>
      <section id="vasu-healthcare">
        <div className="xs:px-4 lg:px-0 relative w-full flex flex-col items-center justify-center lg:container lg:mx-auto py-8">
          <div className="w-full flex flex-col items-center">
            <div className="text-base relative overflow-hidden">
              <p className="font-breadley-sans text-customColors-300 xs:text-4xl lg:text-6xl pb-2">
                Vasu Healthcare
              </p>
              <p className="flex w-full bg-golden" style={{ height: '1px' }} />
            </div>
            <p className="xs:w-full lg:w-3/5 xs:text-base lg:text-xl text-center mt-5">
              Our Mission Is To Commit Ourselves To Mankind’s Pursuit For Longer, Healthier &
              Happier Lives By Providing Remedies From Nature #CaringSince1980
            </p>
          </div>
          <div className="my-8 w-full grid xs:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="w-full flex items-center justify-start">
              <CaringIcon className="xs:w-5 xs:h-5 lg:w-7 lg:h-7" />
              <p className="xs:text-sm lg:text-lg text-customColors-300 ml-2">Caring Since 1980</p>
            </div>
            <div className="w-full flex items-center justify-start">
              <LegacyIcon className="xs:w-5 xs:h-5 lg:w-7 lg:h-7" />
              <p className="xs:text-sm lg:text-lg text-customColors-300 ml-2">40 Years of Legacy</p>
            </div>
            <div className="w-full flex items-center justify-start">
              <ProductsIcon className="xs:w-5 xs:h-5 lg:w-7 lg:h-7" />
              <p className="xs:text-sm lg:text-lg text-customColors-300 ml-2">200+ Products</p>
            </div>
            <div className="w-full flex items-center justify-start">
              <CountriesIcon className="xs:w-5 xs:h-5 lg:w-7 lg:h-7" />
              <p className="xs:text-sm lg:text-lg text-customColors-300 ml-2">50+ Countries</p>
            </div>
            <div className="w-full flex items-center justify-start">
              <AwardsIcon className="xs:w-5 xs:h-5 lg:w-7 lg:h-7" />
              <p className="xs:text-sm lg:text-lg text-customColors-300 ml-2">30+ Awards</p>
            </div>
            <div className="w-full flex items-center justify-start">
              <ResearchIcon className="xs:w-5 xs:h-5 lg:w-7 lg:h-7" />
              <p className="xs:text-sm lg:text-lg text-customColors-300 ml-2">
                Research-based Products
              </p>
            </div>
          </div>
          <div className="w-full flex justify-center mt-4">
            <div className="xs:w-full lg:w-3/5 flex items-center justify-center">
              <img
                src="/images/vasu-healthcare-map.png"
                className="w-full h-full object-contain"
                alt="vasu healthcare centers"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VasuSection;
