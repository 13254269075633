import React from "react";

export const AwardsIcon = ({ className = "" }) => (
  <svg
    className={className}
    width="27"
    height="30"
    viewBox="0 0 27 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.125 0C6.40699 0 5.02328 1.33111 4.82617 3H2.875C1.43878 3 0.25 4.18878 0.25 5.625V10.125C0.25 12.804 2.44605 15 5.125 15H5.28174C6.29183 18.0918 8.94955 20.3183 12.25 20.7715V23.25H9.625C6.94605 23.25 4.75 25.446 4.75 28.125V28.875C4.75003 29.1734 4.86857 29.4595 5.07954 29.6705C5.29051 29.8814 5.57664 30 5.875 30H20.875C21.1734 30 21.4595 29.8814 21.6705 29.6705C21.8814 29.4595 22 29.1734 22 28.875V28.125C22 25.446 19.804 23.25 17.125 23.25H14.5V20.7715C17.8004 20.3183 20.4582 18.0918 21.4683 15H21.625C24.304 15 26.5 12.804 26.5 10.125V5.625C26.5 4.18878 25.3112 3 23.875 3H21.9238C21.7267 1.33111 20.343 0 18.625 0H8.125ZM8.125 2.25H18.625C19.2599 2.25 19.75 2.74013 19.75 3.375V3.94043C19.7302 4.0612 19.7302 4.1844 19.75 4.30518V12.375C19.75 15.8864 16.9418 18.7003 13.4395 18.7368C13.3965 18.7344 13.3535 18.7344 13.3105 18.7368C9.80819 18.7003 7 15.8864 7 12.375V4.30957C7.01984 4.1888 7.01984 4.0656 7 3.94482V3.375C7 2.74013 7.49013 2.25 8.125 2.25ZM2.875 5.25H4.75V12.375C4.75 12.486 4.8088 12.5784 4.81299 12.6885C3.50819 12.53 2.5 11.4765 2.5 10.125V5.625C2.5 5.40372 2.65372 5.25 2.875 5.25ZM22 5.25H23.875C24.0963 5.25 24.25 5.40372 24.25 5.625V10.125C24.25 11.4765 23.2418 12.53 21.937 12.6885C21.9412 12.5784 22 12.486 22 12.375V5.25ZM9.625 25.5H17.125C18.454 25.5 19.489 26.4773 19.6753 27.75H7.07471C7.26104 26.4773 8.29597 25.5 9.625 25.5Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="6.26037"
        y1="21.2008"
        x2="28.5182"
        y2="15.3354"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
