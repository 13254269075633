import React from 'react';
import { CodIcon } from '../../static/svg/CodIcon';
import { FreeShipIcon } from '../../static/svg/FreeShipIcon';
import { SatisfactionIcon } from '../../static/svg/SatisfactionIcon';

const CuratedSection = () => {
  return (
    <>
      <section id="curated" className="w-full flex flex-wrap bg-white relative">
        <img
          src="/images/gold-frame-1.png"
          className="absolute top-0 left-0 h-1/3 object-contain lg:h-9/12"
        />
        <img
          src="/images/gold-frame-2.png"
          className="absolute bottom-0 xs:h-1/2 object-contain right-0 lg:h-full"
        />
        <div className="xs:px-4 lg:px-0 relative w-full flex flex-col items-center justify-center lg:container lg:mx-auto py-8">
          <div className="w-full flex flex-row justify-between xs:items-start lg:items-center py-4 lg:-mx-5">
            <div className="xs:flex-1 lg:flex-none lg:w-48 lg:h-48 rounded-full lg:bg-customBackground-600 lg:bg-opacity-10 flex xs:items-start lg:content-center lg:items-center justify-center flex-wrap">
              <FreeShipIcon className="xs:w-8 xs:h-8 lg:w-10 lg:h-10" />
              <p className="xs:px-5 lg:px-0 w-full text-center xs:text-sm lg:text-lg capitalize mt-5">
                Free Shipping
              </p>
            </div>

            <div className="xs:flex-1 lg:flex-none lg:w-48 lg:h-48 rounded-full lg:bg-customBackground-600 lg:bg-opacity-10 flex xs:items-start lg:content-center lg:items-center justify-center flex-wrap">
              <CodIcon className="xs:w-8 xs:h-8 lg:w-10 lg:h-10" />
              <p className="w-full text-center xs:text-sm lg:text-lg capitalize mt-5">
                Cash On Delivery
              </p>
            </div>

            <div className="xs:flex-1 lg:flex-none lg:w-48 lg:h-48 rounded-full lg:bg-customBackground-600 lg:bg-opacity-10 flex xs:items-start lg:content-center lg:items-center justify-center flex-wrap">
              <SatisfactionIcon className="xs:w-8 xs:h-8 lg:w-10 lg:h-10" />
              <p className="w-full text-center xs:text-sm lg:text-lg capitalize mt-5">
                Satisfaction Guaranteed
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full flex flex-wrap">
        <div className="w-full flex flex-wrap lg:flex-row lg:flex-nowrap justify-start">
          <div className="w-full lg:w-1/2 flex items-center justify-center">
            {/* <img src="/images/curated-with-nature.jpg" className="w-full h-full object-contain" /> */}
            <video
              className="xs:w-full xs:h-auto lg:w-full lg:h-full"
              loop
              autoPlay
              muted
              playsInline
            >
              <source src="/videos/curated-video.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="xs:w-full lg:w-1/2 xs:px-4 lg:px-0 flex items-center justify-center bg-customBackground-200 xs:pb-5 lg:pb-0">
            <div className="xs:w-full lg:w-4/5">
              <p className="w-full text-left font-breadley-sans text-customColors-300 xs:text-4xl lg:text-6xl xs:mb-4 lg:mb-8">
                How We Define Natural:
              </p>
              <p className="text-base leading-relaxed lg:pr-14 xs:mb-4 lg:mb-14">
                An ingredient or formula composed of plants, minerals or herbs that undergoes
                chemical changes due to biological processes and is harvested purely from the wild.
              </p>
              <div className="w-full overflow-hidden">
                <a
                  href="#"
                  className="cursor-pointer text-center text-lg text-customColors-300 default-a after:bg-customColors-300 reverse relative overflow-hidden"
                >
                  Know more
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CuratedSection;
