import { mediaType } from '@components/libs/typeInterface';
import React from 'react';
import ProductSlider from './ProductSlider';

interface IProductImage {
  isModal?: boolean;
  media: mediaType[];
  index: number;
  name: string;
  img?: string;
  link: string;
}

const ProductImage = (props: IProductImage) => {
  const { isModal, media, index, name, img, link } = props;

  // let csStyle = {};

  // if (index === 1) {
  //   csStyle = {
  //     transform: 'scaleX(-1) rotate(180deg)'
  //   };
  // } else if (index === 2) {
  //   csStyle = {
  //     transform: 'scaleX(-1)'
  //   };
  // } else {
  //   csStyle = {};
  // }
  return (
    <>
      <div
        className={
          `xs:w-full lg:w-1/2 flex items-center justify-center` +
          `${isModal ? ' lg:h-98' : ''}` +
          `${!isModal && index === 1 ? ' xs:order-0 lg:order-1' : ''}`
        }
      >
        {isModal ? (
          <>
            <ProductSlider media={media} name={name} />
          </>
        ) : (
          <>
            <a href={link} className="w-full flex items-center justify-center relative">
              {/* <img
                style={csStyle}
                src="/images/product-bg.jpg"
                className="relative w-full object-contain left-0 top-0"
              /> */}
              <div className=" w-full h-full flex items-center justify-center">
                <img src={img} className="xs:w-full xs:h-full object-contain z-10" alt={name} />
              </div>
            </a>
          </>
        )}
      </div>
    </>
  );
};

export default ProductImage;
